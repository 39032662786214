<template>
  <div>

    <div class="card card-custom card-stretch gutter-b">
      <div class="card-body pt-4">
        <b-form inline class="boxSearch">
          <b-input
              class="mb-3 mr-sm-3 "
              style="width: 250px"
              placeholder="Mã, Tên hoặc Số điện thoại"
              v-model="search.name"
              trim
          ></b-input>


          <b-form-select
              class="mr-sm-3 mb-3 width-200"
              v-model="search.city"
              :options="city"
          ></b-form-select>
          <b-form-select
              class="mr-sm-3 mb-3 width-200"
              v-model="search.level"
              :options="level"
          ></b-form-select>
          <button type="button" class="btn float-right btnBase btn-secondary mb-3" @click="searchForm()">Tìm kiếm
          </button>
        </b-form>

        <template>
          <b-table
              :items="user.items"
              :fields="user.fields"
              :busy="isBusy"
              :select-mode="selectMode"
              show-empty
              class="emptyRoomTable"
              id="tableManagementAgent"
              v-clickOutside="closeEvent,clearSelected"
              :head-variant="'light'"
              responsive="sm"
              ref="selectableTable"
              selectable
              @row-selected="onRowSelected"
          >

            <template #empty="scope">
              <div role="alert" aria-live="polite">
                <div class="text-center my-2">Hiện chưa có dữ liệu</div>
              </div>
            </template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <!--                <strong>Loading...</strong>-->
              </div>
            </template>
            <!-- A custom formatted column -->
            <template #cell(code)="data">
              <p class="emptyRoomTable-name mb-0">
                {{ data.item.code }}
              </p>
            </template>
            <template #cell(name)="data">
              <div class="managementAgentAgency">
                <p class="mrb-8 mb-0">
                  {{ data.item.name }}
                </p>
                <a :href="data.item.link" target="_blank">{{ data.item.link }}</a>
              </div>
            </template>
            <template #cell(phone)="data">
              <div class="managementAgentInfo">
                <p class="mrb-8 mb-0">
                  {{ data.item.phone }}
                </p>
              </div>

            </template>

            <!-- A virtual composite column -->
            <template #cell(address)="data">

              <p class=" mb-0">
                {{ data.item.address }}
              </p>
            </template>
            <template #cell(hotelType)="data">

              <p class=" mb-0">
                <b>{{ data.item.hotelType }}</b>
              </p>
            </template>

            <template #cell(status)="data">
              <b-badge style="color: #fff;background: #C0F256;" variant="success" v-if="data.item.status === 0">Tài khoản dùng thử
              </b-badge>
              <b-badge style="color: #fff;background: #219653;" variant="success" v-if="data.item.status === 1">Hoạt
                động
              </b-badge>
              <b-badge class="badge-warning" style="color: #fff" v-if="data.item.status === 2">Khóa</b-badge>
              <b-badge class="badge-danger" style="color: #fff" v-if="data.item.status === 3">Đã xóa</b-badge>
              <b-badge class="badge-danger" style="background: #9B51E0;color: #fff" v-if="data.item.status === 4">Hết
                hạn
              </b-badge>
              <b-badge class="badge-danger" style="background: #9B51E0;color: #fff" v-if="data.item.status === 5">Hết hạn dùng thử
              </b-badge>
            </template>
            <template #cell(expired_at)="data">
              <p style="color: #828282" class=" mb-0"> {{ data.item.expired_at }}</p>
            </template>
            <template #cell(created_at)="data">
              <p class=" mb-0"> {{ data.item.created_at }}</p>
            </template>
          </b-table>
          <div class="clearfix" v-if="totalPages > 10">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalPages"
                :per-page="pageSize"
                aria-controls="my-table"
                class="float-right"
            >
            </b-pagination>

          </div>


        </template>
      </div>
    </div>
    <div class="dropdownRoom" :style="{ top: topDropdown, left: leftDropdown }" v-if="showAction">
      <ul>
        <li @click="showEditCustomer">Cập nhật khách hàng</li>
        <li @click="resetPass()">Cài đặt mật khẩu</li>
        <li @click="contractExtension()">Gia hạn hợp đồng</li>
        <li style="color: #F2994A;" @click="lock">Khóa khách hàng</li>
        <li style="color: #EB5757;" @click="deleteHotel">Xóa khách sạn</li>
        <li style="color: #219653;" @click="unLock">Kích hoạt lại</li>
        <li style="color: #2F80ED;" @click="activateContract">Kích hoạt hợp đồng</li>
      </ul>
    </div>
    <reset-pass @reload="fetch(currentPage)" ref='resetPass'></reset-pass>
    <contract-extension @reload="fetch(currentPage)" ref='contractExtension'></contract-extension>
    <modalAddCustomer
        ref="modalAddCustomer"
        @reload="fetch(currentPage)"
        :list-province="listProvince"
        :list-type="listType"
    ></modalAddCustomer>
    <modalEditCustomer
        ref="modalEditCustomer"
        @reload="fetch(currentPage)"
        :list-province="listProvince"
        :list-type="listType"
        :get-data="getData"
    ></modalEditCustomer>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import BaseService from "@/core/helper/base.service";
import {clickOutside} from "@/core/mixins/clickOutside";
import ResetPass from "@/view/pages/agent/resetPass";
import ContractExtension from "@/view/pages/agent/contractExtension";
import modalAddCustomer from "@/view/pages/share/components/modalAddCustomer";
import modalEditCustomer from "@/view/pages/share/components/modalEditCustomer";

export default {
  name: "managementAgent",
  mixins: [clickOutside],

  components: {
    ContractExtension,
    ResetPass,
    modalAddCustomer,
    modalEditCustomer
  },
  data() {
    return {
      // clickOutside
      idTable: 'tableManagementAgent',
      // end clickOutside

      isBusy: false,
      search: {
        name: '',
        level: null,
        city: null
      },
      pageSize: 10,
      currentPage: 1,
      totalPages: 0,
      level: [],
      city: [],
      selectMode: 'single',
      user: {
        fields: [
          {key: 'code', label: 'Mã', class: 'text-center'},
          {key: 'name', label: 'Tên doanh nghiệp'},
          {key: 'address', label: 'Khu vực'},
          {key: 'phone', label: 'Số điện thoại', class: 'text-center'},
          {key: 'hotelType', label: 'Loại hình', class: 'text-center'},
          {key: 'status', label: 'Trạng thái', class: 'text-center'},
          {key: 'expired_at', label: 'Hạn hợp đồng', class: 'text-center'},
          {key: 'created_at', label: 'Thời gian tạo', class: 'text-center'},

        ],
        items: [],
      },
      isEditAgent: false,
      listProvince: [],
      listType: [],
      getData: {}
    };
  },
  created() {
    this.fetch(this.currentPage)
    this.cityApi();
    this.levelApi();
  },
  watch: {
    // "currentPage"(newPage, oldPage) {
    //   this.fetch(newPage);
    // },
    currentPage: {
      handler: function (newPage, oldPage) {
        console.log(123, newPage)
        this.fetch(newPage)
      }
    },
    "$store.state.staff.activeShow"(val) {
      console.log(val)
      if (!val) {
        this.$refs.modalAddCustomer.handleCancel()
      } else {
        this.$refs.modalAddCustomer.showModal()
      }
    }

  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
    // rowsUser() {
    //   return this.user.items.length
    // },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Khách hàng"}
    ]);
  },
  destroyed() {
  },
  methods: {
    hidenModalAgent() {
      this.$refs.modalAgent.hide()
      this.fetch(this.currentPage)
    },
    showEditCustomer() {
      let id = this.selected[0].id
      ApiService.query(`customer/${id}`).then((response) => {
        if (response.data.code === 1) {
          let data = response.data.data
          this.getData = {
            owner: data.owner,
            phone: data.phone,
            password: '***********',
            prefix: data.prefix,
            email: data.email,
            type: data.type,
            address: data.address,
            id: data.id,
            hotel_name: data.hotel_name,
            expired_month: data.expired_month,
            province_id: data.province_id,
            district_id: data.district_id,
            ward_id: data.ward_id,
          }
          this.$refs.modalEditCustomer.showModal()
        } else {
          BaseService.makeToast('danger', response.data.message, this)
        }
      }).catch(({err}) => {
        console.log(err)
        BaseService.makeToast('danger', err.response.data.message, this)
      });
    },
    activateContract() {
      let id = this.selected[0].id
      ApiService.put(`customer/${id}/active-contract`).then((response) => {
        if (response.data.code === 1) {
          BaseService.makeToast('success', 'Kích hoạt hợp đồng thành công', this)
          this.fetch(this.currentPage)
        } else {
          BaseService.makeToast('danger', response.data.message, this)
        }
      }).catch(({err}) => {
        console.log(err)
      });
    },
    deleteHotel() {
      let id = this.selected[0].id
      ApiService.delete(`customer/${id}`).then((response) => {
        if (response.data.code === 1) {
          BaseService.makeToast('success', 'Xóa khách hàng thành công', this)
          this.fetch(this.currentPage)
        } else {
          BaseService.makeToast('danger', response.data.message, this)
        }
      }).catch(({err}) => {
        console.log(err)
        BaseService.makeToast('danger', err.response.data.message, this)
      });
    },
    lock() {
      let id = this.selected[0].id
      ApiService.delete(`customer/${id}/block`).then((response) => {
        if (response.data.code === 1) {
          BaseService.makeToast('success', 'Khóa khách hàng thành công', this)
          this.fetch(this.currentPage)
        } else {
          BaseService.makeToast('danger', response.data.message, this)
        }
      }).catch(({err}) => {
        console.log(err)
        BaseService.makeToast('danger', err.response.data.message, this)
      });
    },
    unLock() {
      let id = this.selected[0].id
      ApiService.put(`customer/${id}/active`).then((response) => {
        if (response.data.code === 1) {
          BaseService.makeToast('success', 'Kích hoạt thành công', this)
          this.fetch(this.currentPage)
        } else {
          BaseService.makeToast('danger', response.data.message, this)
        }
      }).catch(({err}) => {
        console.log(err)
        BaseService.makeToast('danger', err.response.data.message, this)
      });
    },
    fetch(currentPage) {
      this.isBusy = true;
      let query = {
        page: currentPage - 1,
        pageSize: this.pageSize,
        q: this.search.name ? this.search.name : '',
        type: this.search.level ? this.search.level : '',
        province_id: this.search.city ? this.search.city : '',
      }
      ApiService.query('customer', query)
          .then(({data}) => {
            this.isBusy = false;
            this.totalPages = data.data.pagination.totalElements;
            this.user.items = data.data.items
          })
          .catch(({err}) => {
            this.isBusy = false;
            console.log(err)
          });
    },
    searchForm() {
      // BaseService.makeToast('success', 'hgerwe', this)
      this.currentPage = 1;
      this.fetch(this.currentPage)
      console.log(this.search)
    },
    cityApi() {
      BaseService.getCity().then((res) => {
        let arr = []
        this.listProvince =  [{name: 'Tỉnh thành', id: null}, ...res.data.data.items]
        res.data.data.items.map((res2) => {
          arr.push({
            text: res2.name,
            value: res2.id
          })
        })
        this.city = [{text: 'Tỉnh thành', value: null}, ...arr]
        console.log('city', this.city)

      })
    },
    levelApi() {
      BaseService.getHotelType().then((res) => {
        let arr = []
        res.data.data.items.map((res2) => {
          arr.push({
            text: res2.label,
            value: res2.id
          })
        })
        this.level = [{text: 'Loại hình', value: null}, ...arr]
        let arr2 = []
        res.data.data.items.map((res2) => {
          arr2.push({
            name: res2.label,
            id: res2.id
          })
        })
        this.listType = [{name: 'Loại hình', id: null}, ...arr2]
      })
    },

    contractExtension() {
      let id = this.selected[0].id
      this.$refs.contractExtension.showModal(id);
    },
    resetPass(){
      let id = this.selected[0].id
      this.$refs.resetPass.showModal(id);
    }


  },

};
</script>
<style scoped lang="scss">
.emptyRoomTable {
  p, span {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
}

.emptyRoomTable-floor {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #4F4F4F;
}

.emptyRoomTable-typeRoom {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #219653;
}

.emptyRoomTable-name {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.emptyRoomTable-human {
  p {
    margin-bottom: 8px;

    &:first-child {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #4F4F4F;
    }

    &:last-child {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #828282;
    }
  }
}

.emptyRoomTable-date {
  p {
    margin-bottom: 5px;

    i {
      margin-right: 4px;
      position: relative;
      top: 2px;
    }
  }
}

.type-dot:before {
  content: "\2022";
  padding-right: .2rem;
}

.priceRoom {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #4F4F4F;
    margin-bottom: 5px;

    &:last-child {
      font-weight: 500;
    }
  }
}

.priceRoom2 {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;
  }
}

.colorGreen {
  color: #219653;

}

.colorRed {
  color: red;

}

.boxSearch {
  margin-bottom: 20px;
}

.iconSame {
  width: 18px;
  height: 18px;
  background: #F3F6F9;
  border-radius: 4px;
  //padding: 1px;
  cursor: pointer;

  &:first-child {
    margin-right: 8px;
    padding-top: 1px;
    padding-left: 1px;
  }

  &:last-child {
    padding-top: 1px;
    margin-left: 8px;
  }
}

.boxSearch {
  .bd-highlight {
    margin-right: 15px;

    .custom-checkbox {
      margin-top: 12px;

    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.managementAgentAgency {
  p {
    &:first-child {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #4F4F4F;
    }

    &:last-child {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #9B51E0;
    }
  }
}

.managementAgentInfo {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    &:first-child {
      color: #4F4F4F;
    }

    &:last-child {
      color: #333333;
    }
  }
}
</style>


<!--name: "managementAgent",-->
<!--this.$store.dispatch(SET_BREADCRUMB, [{ title: "Quản lý đại lý" }]);-->
