<template>
  <div class="formAddRoom">
    <a-modal v-model="visible" width="800px" title="Cập nhật khách hàng" on-ok="handleOk" @cancel="handleCancel">
      <template slot="footer">
        <!--        <a-button key="back" @click="handleCancel">-->
        <!--          Đóng-->
        <!--        </a-button>-->
        <a-button key="submit" class="btnBase" type="primary" @click="handleSubmitForm">
          Lưu
        </a-button>
      </template>

      <a-form-model ref="form" :model="form" :rules="rules">
        <a-form-model-item ref="hotel_name" label="Tên khách sạn" prop="hotel_name">
          <a-input
              type="text"
              placeholder="Tên khách sạn..."
              v-model="form.hotel_name"
          />
        </a-form-model-item>
        <a-form-model-item ref="owner" label="Quản lý" prop="owner">
          <a-input
              type="text"
              placeholder="Tên quản lý..."
              v-model="form.owner"
          />
        </a-form-model-item>
        <a-row :gutter="10">
          <a-col  :span="12">
            <a-form-model-item ref="phone" label="Số điện thoại" prop="phone">
              <a-input
                  type="text"
                  placeholder="Nhập số điện thoại..."
                  v-model="form.phone"
              />
            </a-form-model-item>
          </a-col>
          <a-col  :span="12">
            <a-form-model-item ref="password" label="Mật khẩu" prop="password">
              <a-input-password v-model="form.password"  placeholder="Nhập password..."
                                disabled
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="10">
          <a-col  :span="12">
            <a-form-model-item ref="prefix" label="Prefix" prop="prefix">
              <a-input
                  type="text"
                  placeholder="Nhập prefix..."
                  v-model="form.prefix"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item ref="email" label="Email" prop="email">
              <a-input
                  type="text"
                  placeholder="Nhập email..."
                  v-model="form.email"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="10">
          <a-col :span="12">
            <a-form-model-item ref="expired_month" label="Thời hạn" prop="expired_month">
              <a-input-number :min="1" :max="12" :step="1" style="width: 100%" placeholder="Nhập số tháng..."
                              v-model="form.expired_month" disabled/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item ref="province_id" label="Tỉnh thành" prop="province_id">
              <a-select v-model="form.province_id" placeholder="Tỉnh thành..." @change="getDistrict(form.province_id,false)">
                <template v-for="(item) of listProvince">
                  <a-select-option :value="item.id" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </template>
              </a-select>
            </a-form-model-item>

          </a-col>
        </a-row>
        <a-row :gutter="10">
          <a-col :span="12">
            <a-form-model-item ref="district_id" label="Quận huyện" prop="district_id">
              <a-select v-model="form.district_id" placeholder="Quận huyện..." @change="getWard(form.district_id,false)">
                <template v-for="(item) of listDistrict">
                  <a-select-option :value="item.id" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </template>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item ref="ward_id" label="Xã phường" prop="ward_id">
              <a-select v-model="form.ward_id" placeholder="Xã phường...">
                <template v-for="(item) of listWard">
                  <a-select-option :value="item.id" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </template>
              </a-select>
            </a-form-model-item>

          </a-col>
        </a-row>
        <a-row :gutter="10">
          <a-col :span="12">

            <a-form-model-item ref="address" label="Địa chỉ" prop="address">
              <a-input
                  type="text"
                  placeholder="Nhập địa chỉ..."
                  v-model="form.address"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item ref="type" label="Loại hình" prop="type">
              <a-select v-model="form.type" placeholder="Loại hình...">
                <template v-for="(item) of listType">
                  <a-select-option :value="item.id" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </template>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>

      </a-form-model>


    </a-modal>
  </div>
</template>

<script>

import ApiService from "@/core/services/api.service";
import BaseService from "@/core/helper/base.service";

export default {
  name: "modalEditCustomer",
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    checkDisabled: {
      type: String,
      default: ''
    },
    listProvince: {
      type: Array,
      default() {
        return []
      }
    },
    listType: {
      type: Array,
      default() {
        return []
      }
    },
    getData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      loading: false,
      visible: false,
      form: {
        id: '',
        hotel_name: '',
        owner: '',
        phone: '',
        password: '',
        prefix: '',
        email: '',
        type: undefined,
        address: '',
        expired_month: '',
        province_id: undefined,
        district_id: undefined,
        ward_id: undefined
      },
      rules: {
        hotel_name: [
          {
            required: true,
            message: "Tên khách sạn không để trống",
            trigger: "change",
          },
        ],
        owner: [
          {
            required: true,
            message: "Tên quản lý không để trống",
            trigger: "change",
          },
        ],

        password: [
          {
            required: true,
            message: "Mật khẩu không để trống",
            trigger: "change",
          },
        ],
        prefix: [
          {
            required: true,
            message: "Prefix không để trống",
            trigger: "change",
          },
        ],
        province_id: [
          {
            required: true,
            message: "Tỉnh thành không để trống",
            trigger: "change",
          },
        ],
        district_id: [
          {
            required: true,
            message: "Quận huyện không để trống",
            trigger: "change",
          },
        ],
        ward_id: [
          {
            required: true,
            message: "Xã phường không để trống",
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            message: "Loại hình không để trống",
            trigger: "change",
          },
        ],
        address: [
          {
            required: true,
            message: "Địa chỉ không để trống",
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            message: "Số điện thoại không để trống",
            trigger: "change",
          },
        ],
        expired_month: [
          {
            required: true,
            message: "Thời hạn không để trống",
            trigger: "change",
          },
        ],
      },
      // listProvince: [],
      listDistrict: [{name: 'Quận huyện', id: null}],
      listWard: [{name: 'Phường xã', id: null}],
      // listType: []
    };
  },
  watch: {
    getData() {
      this.form = {...this.getData}
      this.getDistrict(this.form.province_id,true)
      this.getWard(this.form.district_id,true)
    }
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    handleSubmitForm(e) {
      (this.$refs.form).validate((valid) => {
        if (valid) {
          this.onSubmit();
        }
      });
    },
    onSubmit() {
      this.loading = true;
      let form = {
        id: this.form.id,
        owner: this.form.owner,
        phone: this.form.phone,
        prefix: this.form.prefix,
        email: this.form.email,
        type: this.form.type,
        address: this.form.address,
        hotel_name: this.form.hotel_name,
        province_id: this.form.province_id,
        district_id: this.form.district_id,
        ward_id: this.form.ward_id
      }
      console.log(form)
      ApiService.put(`customer`,form).then((response) => {
        if (response.data.code === 1) {
          BaseService.makeToast('success', 'Sửa khách hàng thành công', this)
          this.$emit('reload', true)
          this.handleCancel();
        } else {
          BaseService.makeToast('danger', response.data.message, this)
        }
      }).catch(({err}) => {
        console.log(err)
      });
    },
    handleCancel(e) {
      this.visible = false;
      (this.$refs.form).resetFields();
      this.$store.commit('SET_STATUS_SHOW_MODAL_STAFF', this.visible);
    },
    // call api
    getDistrict(id,status) {

      if (!status) {
        this.form.district_id = undefined
        this.form.ward_id = undefined
      }
      let filter = {
        province_id : id
      }
      BaseService.getDistrict(filter).then((res) => {
        this.listDistrict =  [{name: 'Quận huyện', id: null}, ...res.data.data.items]
      })
    },
    getWard(id,status) {
      if (!status) {
        this.form.ward_id = undefined
      }
      let filter = {
        district_id : id
      }
      BaseService.getWard(filter).then((res) => {
        this.listWard =  [{name: 'Phường xã', id: null}, ...res.data.data.items]
      })
    },

  },
}
</script>

<style scoped>

</style>
