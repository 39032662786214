<template>
  <div class="formAddRoom">
    <a-modal v-model="visible" title="Đặt lại mật khẩu" on-ok="handleOk" @cancel="handleCancel">
      <template slot="footer">
<!--        <a-button key="back" @click="handleCancel">-->
<!--          Đóng-->
<!--        </a-button>-->
        <a-button key="submit" class="btnBase" type="primary" @click="handleOk">
          Lưu
        </a-button>
      </template>
      <a-form-model ref="form" :model="form" :rules="rules">
        <a-form-model-item ref="passNew"
                           :label="'Mật khẩu mới'"
                           prop="passNew">
          <a-input-password v-model="form.passNew" placeholder="Nhập mật khẩu mới..." />
        </a-form-model-item>
      </a-form-model>

    </a-modal>

  </div>


</template>

<script>
// import _ from 'lodash';


import ApiService from "@/core/services/api.service";
import BaseService from "@/core/helper/base.service";

export default {
  name: "resetPass",
  components: {
  },
  data() {
    return {
      selectId: '',
      visible: false,
      form: {
        passNew: '',
      },
      rules: {
        passNew: [
          {
            required: true,
            message: "Mật khẩu không để trống",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {},

  destroyed() {
  },
  watch: {

  },
  created() {

  },
  mounted() {

  },

  methods: {
    showModal(id){
      this.visible = true;
      this.selectId = id;
    },
    handleCancel(e) {
      this.visible = false;
      (this.$refs.form).resetFields();
    },
    handleOk(e) {
      (this.$refs.form).validate((valid) => {
        if (valid) {
          console.log(111,this.form)
          let body = {
            password: this.form.passNew
          }
          ApiService.put(`customer/${this.selectId}/reset-password`,body).then((response) => {
            if (response.data.code === 1) {
              BaseService.makeToast('success', 'Đặt lại mật khẩu thành công', this)
              this.$emit('reload', true)
              this.handleCancel();
            } else {
              BaseService.makeToast('danger', response.data.message, this)
            }
          }).catch(({err}) => {
            console.log(err)
          });

          //thanh cong, dong modal
        }
      });

    },
  }
};
</script>
<style lang="scss" scoped>


</style>
