import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {LOGIN} from "@/core/services/store/auth.module";

export const clickOutside =
    {
        components: {
            directives: {
                clickOutside: {
                    priority: 700,
                    bind() {
                        let self = this
                        this.event = function (event) {
                            console.log('emitting event')
                            self.vm.$emit(self.expression, event)
                        }
                        this.el.addEventListener('click', this.stopProp)
                        document.body.addEventListener('click', this.event)
                    },

                    unbind() {
                        console.log('unbind')
                        this.el.removeEventListener('click', this.stopProp)
                        document.body.removeEventListener('click', this.event)
                    },
                    stopProp(event) {
                        event.stopPropagation()
                    }
                }
            }
        },
        data() {
            return {
                showAction: false,
                topDropdown: 0,
                leftDropdown: 0,
                selected: [],
                refTable:'selectableTable',
                idTable:'',

            }
        },
        mounted() {
            // click item in table
            // console.log('this.idTable', this.idTable)
            if(this.idTable){
                document.getElementById(this.idTable).getElementsByTagName("TBODY")[0].addEventListener("click", this.showLocation);
            }
            // document.getElementById("tableBase").getElementsByTagName("TBODY")[0].setAttribute("id", "headInTable");
            // document.getElementById("headInTable").addEventListener("click", this.showLocation);
        },
        methods: {
            clearSelected() {
                // console.log()
                if(this.$refs[this.refTable]){
                    this.$refs[this.refTable].clearSelected()
                }
            },
            showLocation(event) {
                var cX = event.clientX;
                var cY = event.clientY;
                this.topDropdown = `${cY}px`;
                this.leftDropdown = `${cX}px`;
            },

            actionRoom() {
                this.showAction = true
            },
            closeEvent () {
                this.showAction = false;
            },
            onRowSelected(items) {
                // console.log('items', items)

                if (items.length > 0) {
                    this.actionRoom();
                    this.selected = items
                    // console.log('this.selected',this.selected, typeof this.selected)
                } else {
                    this.closeEvent();
                }
            },

        }
    }
